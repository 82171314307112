import React from "react";
import "./Background.css";
import CustomAccordion from "shared/Accordion/Accordion";

const Background = () => {
  return (
    <div>
      <div data-testid="wrapper" className="wrapper">
        {/* PAGE TITLE  */}
        <div data-testid="the-title" className="the-title">
          Background
        </div>

        {/* 1. INTRODUCTION  */}
        <CustomAccordion testId="challenge-title" title="Introduction">
          <p>
            Large Language Models (LLMs) have experienced significant expansion,
            finding applications across diverse fields including personalized
            healthcare, predictive maintenance in industry, and customer service
            automation (Maslej et al., 2023).
          </p>
          <p>
            As highlighted by Liu et al. (2023), LLMs can be exploited through
            various attacks by malicious users, emphasizing the importance of
            ongoing vigilance and robust security frameworks. The notion of
            jailbreaking attacks to circumvent the restrictions placed on LLMs
            has emerged as a growing area of concern and research focus (Li,
            Zheng, & Huang, 2024).
          </p>
          <p>
            Building on this concept, Jia et al. (2024) propose several improved
            techniques for optimization-based jailbreaking of large language
            models. Their approach demonstrates that diverse target templates
            and an automatic multi-coordinate updating strategy can
            significantly enhance the efficiency and effectiveness of these
            jailbreak efforts, further illuminating the paths toward more
            resilient LLMs.
          </p>
          <p>
            Alexander, Nika, & Jacob (2023) propose that one effective method to
            grasp the limitations of LLM safety filters is to delve into their
            vulnerability to jailbreaking attacks. This approach not only
            exposes existing weaknesses but also paves the way for the
            development of robust security frameworks tailored to counteract
            such exploits.
          </p>
          <p>
            In the context of LLMs increasingly being deployed in sensitive and
            critical domains, the urgency for addressing jailbreaking
            vulnerabilities becomes even more pronounced. By proactively
            identifying and mitigating these specific vulnerabilities, we can
            ensure the safe and beneficial application of LLMs across various
            societal sectors, thereby reinforcing their security posture and
            alignment with ethical standards.
          </p>
        </CustomAccordion>

        {/* 2. CHALLENGE FOCUS */}
        <CustomAccordion
          testId="challenge-related-work"
          title="Challenge Focus"
        >
          {/* <div className="figure-two">
            <img src={sharma} alt="figure2"></img>
          </div> */}

          {/* 2.1 Objective */}
          <div>
            <h3 className="subheaders-font-style">Objective</h3>
            <p>
              The Challenge will focus on identifying and understanding
              jailbreaking weaknesses in popular LLMs used in commercial and
              research settings (challenge track 1) and developing robust
              security measures for LLMs, reinforcing their resilience to
              advanced jailbreak attacks (challenge track 2).
            </p>
          </div>

          {/* 2.2 Definitions */}
          <div>
            <h3 className="subheaders-font-style">Definitions</h3>
            <p>
              The landscape of LLMs is rapidly evolving where both the
              capabilities of LLMs and the strategies for their exploitation and
              defense are constantly advancing.
            </p>
            <p>
              For the Challenge, it is useful to define a few key concepts that
              are associated with the task.
            </p>

            <p>
              <b>Jailbreak Attacks: </b>
              These are efforts to manipulate LLMs into producing output that
              violate their designed ethical or operational guidelines.
              Typically, jailbreak attacks exploit prompt engineering or
              adversarial input crafting to bypass or deceive the model's safety
              mechanisms. Notable techniques include both empirical attacks,
              which leverage human ingenuity in prompt crafting, and automated
              methods that systematically probe models to discover
              vulnerabilities (Chao et al., 2023; Liu et al., 2023).
            </p>

            <p>
              <b>Automated Jailbreak: </b>
              This refers to the use of algorithms or models to generate
              jailbreak prompts without human intervention. These methods often
              employ iterative refinement processes and advanced computational
              techniques to optimize the effectiveness of attacks. For example,
              the PAIR technique described by Chao et al. (2023) automates the
              creation of prompts through a black-box access method, efficiently
              bypassing LLM safety protocols.
            </p>

            <p>
              <b>Prompt Injections: </b>
              Prompt injections are a specialized subset of jailbreak attacks.
              Drawing from recent studies like Shayegani et al. (2023), prompt
              injections involve the strategic insertion of specially crafted
              prompts designed to mislead the model into treating these inputs
              as benign instructions, thereby bypassing standard content
              filters.
            </p>

            <p>
              <b>Defense Mechanisms: </b>
              In response to these threats, substantial research has been
              focused on developing robust defenses against jailbreak attacks.
              These defenses range from detection-based strategies, which aim to
              identify and block harmful inputs or outputs, to mitigation
              strategies that alter the processing of inputs to reduce the
              likelihood of harmful output generation. Techniques such as
              content filtering, semantic analysis, and alignment checks are
              commonly employed to fortify LLMs against these sophisticated
              attacks (Zhangchen et al., 2024).
            </p>
          </div>
        </CustomAccordion>

        {/* 3. TASK FORMULATION */}
        {/* <CustomAccordion
          testId="challenge-task-formulation"
          title="Task Formulation"
        >
          <div>
            <h3 className="subheaders-font-style">Objective</h3>
            <p>
              The Challenge aims to develop end-to-end classification techniques
              for identifying harmful memes containing social bias, a subset of
              harmful online content. This focus is intended to address the
              nuanced ways social biases can manifest and propagate through
              memes.
            </p>
          </div>
          <p>&nbsp;</p>
          <div>
            <h3 className="subheaders-font-style">Definitions</h3>
            <p>
              The Online Safety space is fast evolving. For the Challenge, it is
              useful to define a few key concepts that are associated with the
              task.{" "}
            </p>

            <p>
              <b>Harmful Online Content: </b>
              Refers to any form of digital content that can cause
              psychological, reputational, or physical harm to individuals or
              groups. This includes content that promotes hate, violence,
              self-harm, discrimination, or misinformation. Harmful online
              content can have significant real-world impacts, from influencing
              public opinion to inciting real-world actions.
            </p>

            <p>
              <b>Memes: </b>
              Digital content, often image-based with accompanying text, used to
              express ideas, humour, or commentary. Memes are a potent tool for
              cultural expression but can also be used to spread harmful
              content. For this Challenge, the scope is confined to static image
              memes. This specification ensures a standardised format for
              submissions and evaluation, focusing the Challenge on consistent
              digital content.
            </p>

            <p>
              <b>Prejudice: </b>
              An unfair and unreasonable opinion or feeling, especially when
              formed without enough thought or knowledge (Cambridge Dictionary).
            </p>

            <p>
              <b>Social Bias: </b>
              Prejudice towards certain groups based on social characteristics,
              such as age, disabilities, gender, nationality, religion, race,
              socioeconomic status, and sexual orientation.
            </p>
          </div>
          <p>&nbsp;</p>
          <div>
            <h3 className="subheaders-font-style">
              Challenge Focus - Harmful Memes with Social Bias
            </h3>
            <p>
              Instead of encompassing the broader range of harmful online
              content, such as general misinformation, explicit violence, or
              content promoting self-harm, the Challenge is exclusively focused
              on detecting and classifying memes that contain the following
              social biases or otherwise portraying in a negative light the
              following categories:
            </p>
            <ul>
              <li>
                <b>Racial Disparities: </b>
                Memes perpetuating stereotypes or prejudices based on race or
                ethnicity.
              </li>
              <li>
                <b>Religious Beliefs and Practices: </b>
                Memes that mock or demean specific religions or religious
                practices.
              </li>
              <li>
                <b>Sexual Orientation: </b>
                Memes that promotes negative stereotypes or biases about
                different sexual orientations.
              </li>
              <li>
                <b>Nationalistic Sentiments and Xenophobia: </b>
                Memes that foster negative attitudes towards migrants or glorify
                extreme nationalistic views.
              </li>
              <li>
                <b>Socio Economic Divides: </b>
                Memes that highlights or ridicules class struggles and
                socio-economic disparities.
              </li>
              <li>
                <b>Age-Related Biases: </b>
                Memes perpetuating stereotypes or biases based on a person's
                age.
              </li>
              <li>
                <b>Gender Discrimination: </b>
                Memes that promotes gender stereotypes or discriminates based on
                gender.
              </li>
              <li>
                <b>Discrimination Based on Illnesses and Disabilities: </b>
                Memes that mock or belittle individuals with illnesses or
                disabilities.
              </li>
            </ul>
            <p>
              These social biases can perpetuate or amplify prejudices against
              specific groups or individuals, and potentially contribute to
              societal divisions and discrimination through the subtle or overt
              promotion of stereotypes and biased narratives.{" "}
            </p>
          </div>
        </CustomAccordion> */}

        {/* 3. References */}
        <CustomAccordion testId="challenge-references" title="References">
          <ol>
            <li>
              Alexander, W., Nika, H., & Jacob, S. (2023). Jailbroken: How Does
              LLM Safety Training Fail? Thirty-seventh Conference on Neural
              Information Processing Systems. Retrieved from
              https://openreview.net/forum?id=jA235JGM09
            </li>
            <li>
              Gelei, D., Yi, L., Yuekang, L., Kailong, W., Ying, Z., Li, Z., . .
              . Yang, L. (2023). MasterKey: Automated Jailbreak Across Multiple
              Large Language Model Chatbots. Retrieved from
              https://arxiv.org/abs/2307.08715
            </li>
            <li>
              Jia X, Pang T, Du C, et al. Improved techniques for
              optimization-based jailbreaking on large language models[J]. arXiv
              preprint arXiv:2405.21018, 2024.
            </li>
            <li>
              Maslej, N., Fattorini, L., Brynjolfsson, E., Etchemendy, J.,
              Ligett, K., Lyons, T., . . . Perrault, R. (2023). The AI index
              2023 Annual Report. Retrieved from
              https://aiindex.stanford.edu/report/
            </li>
            <li>
              Patrick, C., Alexander, R., Edgar, D., Hamed, H., George, J. P., &
              Eric, W. (2023). Jailbreaking Black Box Large Language Models in
              Twenty Queries. Retrieved from https://arxiv.org/abs/2310.08419
            </li>
            <li>
              Shayegani, E., Md Abdullah , A., Yu, F., Pedram, Z., Yue, D., &
              Nael, A.-G. (2023). Survey of Vulnerabilities in Large Language
              Models. Retrieved from https://arxiv.org/abs/2310.10844
            </li>
            <li>
              Xiaogeng, L., Zhiyuan , Y., Yizhe, Z., Ning , Z., & Chaowe, X.
              (2024). Automatic and Universal Prompt Injection Attacks against
              Large Language Models. Retrieved from
              https://arxiv.org/abs/2403.04957
            </li>
            <li>
              Yi, L., Gelei, D., Yuekang, L., Kailong, W., Zihao, W., Xiaofeng,
              W., . . . Yang, L. (2023). Prompt Injection attack against
              LLM-integrated Applications. Retrieved from
              https://arxiv.org/abs/2306.05499
            </li>
            <li>
              Zhangchen, X., Fengqing, J., Luyao, N., Jinyuan, J., Bill, Y. L.,
              & Radha, P. (2024). SafeDecoding: Defending against Jailbreak
              Attacks via Safety-Aware Decoding. Retrieved from
              https://arxiv.org/abs/2402.08983
            </li>
            <li>
              Zihao, X., Yi, L., Gelei, D., Yuekang, L., & Stjepan, P. (2024).
              LLM Jailbreak Attack versus Defense Techniques -- A Comprehensive
              Study. arXiv. Retrieved from https://arxiv.org/abs/2402.13457
            </li>
          </ol>
        </CustomAccordion>
      </div>
    </div>
  );
};

export default Background;
