import React from "react";
import "./PublicLeaderboard.css";
import datetimeConverter from "utils/datetimeConverter";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import staticFinalLeaderboard from "components/Results/staticFinalLeaderboard.json";

import {
  PUBLIC_BENCHMARK_RECORDS,
  sortedRecords,
  BenchmarkRecordRow,
} from "components/Results/BenchmarkRecords";

const PublicLeaderboard = () => {

  function EnhancedTableHead(props) {
    return (
      <TableHead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 3,
          width: "100%",
          backgroundColor: "#F2F2F2",
        }}
      >
        <TableRow>
          <TableCell
            sx={{
              fontWeight: "bold",
              border: "2px solid black",
              width: 30,
            }}
          >
            <TableHead>Ranks</TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",
              border: "2px solid black",
            }}
          >
            <TableHead>Team Name + Total Submissions</TableHead>
          </TableCell>

          {/* <TableCell
            sx={{
              fontWeight: "bold",
              border: "2px solid black",
              width: 30,
            }}
          >
            <TableHead>Harmonic Mean (ASR)</TableHead>
          </TableCell> */}

          <TableCell
            sx={{
              fontWeight: "bold",
              border: "2px solid black",
              width: 30,
            }}
          >
            <TableHead>Llama-2-7b-chat-hf (ASR)</TableHead>
          </TableCell>

          {/* <TableCell
            sx={{
              fontWeight: "bold",
              border: "2px solid black",
              width: 30,
            }}
          >
            <TableHead>Vicuna-7B (ASR)</TableHead>
          </TableCell> */}
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableToolbar(props) {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%", textAlign: "left" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <b>Public Leaderboard</b>
        </Typography>
      </Toolbar>
    );
  }

  // EnhancedTableToolbar.propTypes = {
  //   numSelected: PropTypes.number.isRequired,
  // };

  // const [selected, setSelected] = React.useState([]);

  const renderTable = () => {
    return (
      <Box
        sx={{
          paddingBottom: "20px",
        }}
      >
        <Paper>
          <EnhancedTableToolbar />

          <TableContainer style={{ overflowX: "initial" }}>
            <Table aria-labelledby="tableTitle" size="small">
              <EnhancedTableHead />

              <TableBody>
                {results?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // return

                  if (!row.team_name) {
                    // check if record has a team_name, if not it is a benchmark record
                    return (
                      <BenchmarkRecordRow
                        row={row}
                        leaderboardType={"public"}
                      />
                    );
                  }

                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.team_id}>
                      {/* Ranking Cell */}
                      <TableCell
                        id={labelId}
                        style={{ border: "2px solid black" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {row?.ranking}
                        </div>
                      </TableCell>

                      {/* Team Info Cell */}
                      <TableCell id={labelId} style={{ border: "2px solid black" }}>
                        <div
                          style={{
                            paddingTop: "10px",
                            width: 450,
                          }}
                        >
                          <div style={{ color: "#ae2514" }}>
                            <b>{row?.team_name}</b>
                          </div>

                          <div
                            style={{
                              color: "grey",
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "5px",
                            }}
                          >
                            <div>
                              {row?.successful_attempts}
                              {" Submission(s)"}
                            </div>
                            <div>
                              Best public score submission on:{" "}
                              {datetimeConverter(row.created_date)}
                            </div>
                          </div>
                        </div>
                      </TableCell>

                      {/* Hmean Cell */}
                      {/*
                      <TableCell style={{ border: "2px solid black" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                          }}
                        >
                          {row?.asr_hmean_public}
                        </div>
                      </TableCell> */}

                      {/* Llama Cell */}
                      <TableCell style={{ border: "2px solid black" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                            width: 75
                          }}
                        >
                          {row?.asr_llama}
                        </div>
                      </TableCell>

                      {/* 
                      <TableCell style={{ border: "2px solid black" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                          }}
                        >
                          {row?.asr_vicuna}
                        </div>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  };

  let leaderboard = staticFinalLeaderboard;

  // // insert benchmark records into the leaderboard
  let results = null;
  if (leaderboard["results"]) {
    if (PUBLIC_BENCHMARK_RECORDS && PUBLIC_BENCHMARK_RECORDS.length > 0) {
      results = sortedRecords(
        // NOTE: putting "...PUBLIC_BENCHMARK_RECORDS" first, followed by "...leaderboard["results"]",
        // causes Teams with exactly the same scores as Baseline models to appear behind the Baseline record.
        // Conversely, putting "...leaderboard["results"]" first, will cause the opposite effect.
        [...PUBLIC_BENCHMARK_RECORDS, ...leaderboard["results"]],
        ["asr_llama"]
      );
    } else {
      results = leaderboard["results"];
    }
  }

  return (
    <>
      {/* <div className="pr-container">
      <div>{renderTable()}</div>
      </div> */}
      <div className="pr-container">
        <div>{renderTable()}</div>
      </div>
    </>
  );
};

export default PublicLeaderboard;
